<template>
  <ion-page>
    <ion-content  :full-screens="true">

        <Header title="Estadísticas"></Header>

        <div class="container">
            <section id="options">
              <div class="box" style="padding-bottom: 0;">
                <div class="list-nav">
                  <ion-grid>
                    <ion-row>
                      <ion-col size="12" size-lg="6">
                        <h1>Resumen</h1>
                      </ion-col>
                    </ion-row>
                    <ion-row class="p-sm np-x">
                      <ion-col size="12" size-md="9">
                        <ion-segment scrollable mode="ios" @ionChange="changeCategory($event)" :value="selectedCategory" class="segmentos">
                          <ion-segment-button value="restaurant" v-if="evolbeUser.business[0].modules.includes('restaurant')">
                            <ion-label>Restaurante</ion-label>
                          </ion-segment-button>
                          <ion-segment-button value="shop" v-if="evolbeUser.business[0].modules.includes('shop')">
                            <ion-label>Tienda Online</ion-label>
                          </ion-segment-button>
                          <ion-segment-button value="experiencias-121" v-if="evolbeUser.business[0].modules.includes('experiencias-121')">
                            <ion-label>Experiencias 12+1</ion-label>
                          </ion-segment-button>
                          <ion-segment-button value="navidad-121" v-if="evolbeUser.business[0].modules.includes('navidad-121')">
                            <ion-label>Navidad 12+1</ion-label>
                          </ion-segment-button>
                          <ion-segment-button value="users" v-if="evolbeUser.business[0].modules.includes('users')">
                            <ion-label>Usuarios</ion-label>
                          </ion-segment-button>
                        </ion-segment>
                      </ion-col>
                      <ion-col size="12" size-md="3">
                          <ion-buttons class="options-nav">
                            <ion-button @click="print($event)">
                              <ion-icon color="dark" slot="icon-only" :icon="printOutline"></ion-icon>
                            </ion-button>
                          </ion-buttons>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </div>
            </section>

            <section id="statistics">
              <ion-grid class="half-box">
                <ion-row v-if="selectedCategory === 'restaurant'">
                  <ion-col size="12" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Pasado y Futuro de Pedidos</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>

                  <ion-col size="6" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                    <ion-card class="card-primary">
                      <ion-card-content>
                        <p>Pedidos Totales</p>
                        <h2>1000</h2>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="6" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <p>Pedidos con Menú del Día</p>
                        <h2>250</h2>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="6" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <p>Pedidos de la Carta</p>
                        <h2>250</h2>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="6" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <p>Pedidos con Promociones</p>
                        <h2>250</h2>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>


                  <ion-col size="12" size-sm="12" size-md="12" size-lg="8" size-xl="8" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Pedidos por Días y Horas</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Procedencia de los pedidos</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>


                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Top Platos</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Top Clientes</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Top Código Postales</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>


                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Total Cupones</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Top Cupones</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Top Promociones</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>


                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Forma de Pago</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Porcentaje de Abandonados</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                  <ion-col size="12" size-sm="12" size-md="6" size-lg="4" size-xl="4" class="tile">
                    <ion-card>
                      <ion-card-content>
                        <h5>Pedidos Cancelados</h5>
                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                </ion-row>
              </ion-grid>

              <p class="text-center m-sm">*Los datos de las estádisticas se actualizan cada 24h</p>
            </section>
        </div>

      <Footer></Footer>
    </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonSegment, IonSegmentButton } from '@ionic/vue';
import { printOutline } from 'ionicons/icons';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default defineComponent({
  name: 'StatisticsResume',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    Header,
    IonSegment,
    IonSegmentButton,
    IonCard,
    IonCardContent
  },
    data() {
      return {
          selectedCategory: undefined
      };
    },
    computed: {
      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions(['openDetails']),

      changeCategory(event){
        this.selectedCategory = event.detail.value;
      }
    },
    beforeMount(){
      if(this.evolbeUser.business[0].modules.includes('restaurant')){
        this.selectedCategory = 'restaurant';
        /*fetch('https://us-central1-docemasunorestaurante-com.cloudfunctions.net/recalculateStatistics_Restaurant_test')
        .then(response => response.json())
        .then(data => console.log(data));*/
      } else if(this.evolbeUser.business[0].modules.includes('shop')){
        this.selectedCategory = 'shop';
      }
    },
    setup() {
      return { printOutline };
    }
});
</script>

<style scoped>
  .tile{
    padding: 0.5rem;
  }

  .tile ion-card{
    display: flex;
    align-items: flex-start;
  }

  .segmentos{
    margin-top: 0.5rem;
  }

</style>